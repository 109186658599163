@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body,
#root {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: 'Inter var',system-ui,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vertical{
  height: 50vh;
  width: 70px;
}

/*Home Page */
.background-video-container {
  position: relative;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.pl-24 {
  position: relative;
  
  z-index: 0;
}

.absolute-blue {
  position: absolute;
  top: 46.3rem;
  left: 0;
  background-color: blue;
  width: 15rem;
  height: 8rem;

  z-index: 0;
}

.video-container {
  position: relative;
  height: 600px;
  width: 2/3;
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}
.text-home{
  z-index: 3;
}

/* Pseudo-classe ::before pour ajouter le filtre bleu */
.video-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.2); 
}

/*Zoom Slider */
@keyframes moveRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.animate-move-right {
  animation: moveRight 2s ease forwards;
}

.animate-move-left {
  animation: moveLeft 2s ease forwards;
}


.slider-content {
display: flex;
width: 200%;
height: 100%;
transition: transform 1s ease ;
}

.slider-item {
flex-shrink: 0;
width: 100%;
}


/* css for Case Studies Component */
.faPlusWrapper {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

.content:hover .faPlusWrapper {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0s linear;
}

.faPlusWrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color:#2d9dd7 ; 
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease;
}

.content:hover .faPlusWrapper a {
  background-color: #2d9dd7; 
}

.faPlusWrapper a svg {
  color: white;
  transition: color 0.3s ease;
}

.content:hover .faPlusWrapper a svg {
  color: white;
}
  
/* Vertical Slider */
.wrapper {
  position: relative;
}

.wrapper .outer {
  display: flex;
  justify-content: center;
}

.wrapper .card {
  background: #fff;
  width: 615px;
  display: flex;
  padding: 20px;
  opacity: 0; 
  pointer-events: none;
  position: absolute;
  justify-content: space-between;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  animation: animate 15s linear infinite;
  animation-delay: calc(3s * var(--delay));
  transform: skewY(-15deg);
}

/* Vertical Slider */
.wrapper {
  position: relative;
}

.wrapper .outer {
  display: flex;
  justify-content: center;
}

.wrapper .card {
  background: #fff;
  width: 615px;
  display: flex;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  justify-content: space-between;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  animation: animate 15s linear infinite;
  animation-delay: calc(3s * var(--delay));
  transform: skewY(-15deg);

}


.wrapper .card.active {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0%) scale(1) translateX(19%);
}



.outer:hover .card {
  animation-play-state: paused;
}

.wrapper .card:last-child {
  animation-delay: calc(-3s * var(--delay));
}


@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(100%) scale(0.5);
  }
  5%, 20% {
    opacity: 1;
    transform: translateY(100%) scale(0.7);
  }
  25%, 40% {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%) scale(1) translateX(19%);
  }
  45%, 60% {
    opacity: 1;
    transform: translateY(-100%) scale(0.7);
  }
  65%, 100% {
    opacity: 0;
    transform: translateY(-100%) scale(0.5);
  }
}

.card .content{
  display: flex;
  align-items: center;
}

.wrapper .card .img {
  height: 100%;
  width: 140px;
  position: absolute;
  right: -5px;
  background: #fff;
  margin-right: 5px;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  transition: background-color 0.3s; 
}


.wrapper .card .img::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  font-size: 86px; 
  color: #fff; 
  opacity: 0;
  transition: opacity 0.3s; 
  background-color: #2d9dd7; 
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper .card .img:hover::before {
  opacity: 0.7; 
}


.wrapper .card {
  
  transition: background-color 0.3s; 
}

.wrapper .card:hover .img::before {
  opacity: 0.7; 
}




.wrapper .card  .bg-secondary-gray {
  border-bottom: 5px solid rgb(171, 171, 171);
  transition: border-color 0.3s; 
  color: rgb(155, 152, 152);
}


.wrapper .card:hover  .bg-secondary-gray {
  border-bottom: 5px solid #2d9dd7; 
  color: #2d9dd7; 
}



.card .img img{
  height: 100%;
  width: 100%;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  object-fit: full;
}
.card .details{
  margin-left: 0px;
  margin-right: 10px;
 
}
.details span{
  font-weight: 600;
  font-size: 20px;
}
.card a{
  text-decoration: none;
  padding: 7px 18px;
  border-radius: 25px;
  color: blue;
  transition: all 0.3s ease;
}
.card a:hover{
  transform: scale(0.94);
}

.arrow {
  /* Add styles for the navigation arrows */
  position: absolute;
 
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 30px;
  color: #0a0673;
}

.top {
  left: 5px;
  bottom :8rem;
}

.bottom {
  
  top:21rem;
}

.card.active {
  /* Add styles for the active card */
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5); /* Example active card shadow */
  
}

.link-style {
  text-decoration: none;
  padding: 7px 18px;
  border-radius: 25px;
  color: blue;
  transition: all 0.3s ease;
  cursor: pointer; /* Add cursor pointer style */
}

.link-style:hover {
  transform: scale(0.94);
} 


/* Zoom card */
.image-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 0; /* Adjust the gap between the images and text as per your preference */
  position: relative;

}

.text {
  width: 400px;
  margin-left: 20px;
  justify-content: space-between;
  grid-column: 2 / span 1;
}


/* Display text on hover icon */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgba(101, 101, 101, 0.7);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 65%;
  left: 80%;
  transform: translateX(-50%);
  width: 150px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.hideScrollbar::-webkit-scrollbar{
  display: none; 
 }


 /*  Meet our family carousel */
 .carousel-blur {

  -webkit-box-shadow:0px 0px 30px 30px rgb(255, 255, 255);
   -moz-box-shadow:0px 0px 30px 30px rgb(255, 255, 255);
   box-shadow:0px 0px 30px 30px rgb(255, 255, 255);
}


.animate-on-scroll {

  opacity: 0;

  transform: translateY(50px);

  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.animate-on-scroll.is-visible {
 
  opacity: 1;

  transform: translateY(0);
}
.rotate-horizontal {
  display: inline-block;
  transform: rotate(40deg) ; 
  font-size: 35px;
}
.rotate-horizontal2 {
  display: inline-block;
  transform: rotate(120deg);
  font-size: 35px;
}
.tilted-card {
  display: inline-block;
  transform: rotate(-180deg);

}
/* responsive nabvar*/

/* Media query pour les écrans plus petits */
@media (max-width: 1300px) {
body{
  overflow-x: hidden;

}
.vertical{
  height: 0vh;
  width: 70px;
}
  .nav-menu {
    display: none !important;
    position: fixed;
    top: 0;
    right: -100%; 
    width:250px; /* Ajustez la largeur comme souhaité */
    height: 100vh; 
    background-color: white; 
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    text-align: right;
  }

  /* Show the menu when the class is applied */
  .show-menu {
    right: -60px;
  }

  /* Style the menu items */
  .nav-menu ul {
    list-style: none;
    padding: 2rem;
  }

  .nav-menu li {
    margin-bottom: 1rem;
   
  }

  .nav-menu.show-menu ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
  }

  .close-menu-button {
    display: block;
    align-self: flex-end;
    margin-top: 20px;
  }
}
/* Responsive navbar */

.nav-menu {
  display: flex;
  align-items: left;

}




.vertical-menu .close-menu-button {
  display: block;
  align-self: flex-end; 
  margin-left: 8px;
  color: #0a0673;

}


/* Media query pour les écrans plus petits */
@media (max-width: 1300px) {

  .nav-menu {
    display: none;
  }

 
  .close-menu-button {
    display: block;

  }


  .vertical-menu {
    display: grid;
    flex-direction: column;
    background-color: #fff;

  }

  .show-menu {
    display: flex !important;

  }
}
/* effet fondu de la disparaission de l'image */

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.fade-out {
  animation: fadeOut 1s 3s forwards; /* Animation de fondu sur 1 seconde après un délai de 3 secondes */
}
/*responsivness navbar */


@media (max-width: 768px) {
  .slider-container {
    display: none;
height: 0 !important;
margin: 0;
  
  }
  .wrapper .card {
    background: #fff;
    width: 400px;
    height: 160px;
    display: flex;
    padding: 20px;
    opacity: 0; 
    pointer-events: none;
    position: absolute;
    justify-content: space-between;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    animation: animate 15s linear infinite;
    animation-delay: calc(3s * var(--delay));
    transform: skewY(-15deg);
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .slider-container {
    margin-top: 20px;
  
    margin-left: 300px;

  }
}

/* responsive card */
.Container {
  display: flex;
  flex-direction: column;
}

.ImageWrapper {
  position: relative;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
  .Container {
    flex-direction: row;
    align-items: center;
  }

  .TextContainer {
    flex: 1;
    padding: 20px;
  }

  .MainButtonRight {
    margin-left: auto;
  }
  
  .CardImgR {
    max-width: 50%;
  }
}



.submenu {
  display: none;
}

.submenu.open {
  display: block;
}

/*blog*/
@media (max-width: 763px) {
  button.absolute.right-20 {
    right: 0px;
    margin-top: 270px;
  }
  button.absolute.-left-10 {
    left: -15px;
    margin-top: 270px;
  }
}
/*turnkey solution */
@media (max-width: 1100px) {
  .h-full1 {
    height: 900px;
    margin-bottom: 150px; 

  }
}

@media (min-width: 1100px) {
  .our {
    
    margin-top: 120px; 
  }
  .h-full1 {
    height: 700px;
    margin-bottom: 50px; 

  }
}

@media (max-width: 1100px) {
  .our {
    
    margin-top: 750px; 
  }
  .h-full1 {
    height: 700px;
    margin-bottom: 120px; 
    margin-left: -20px;
    margin-right: 20px;


  }
}
/* p tet7arrek */
@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px); 
  }
  100% {
    transform: translateY(0);
  }
}

/*table contact us responsive */ 


@media (max-width: 768px) {
  .table-responsive {
  
    width: 100%;
  }
  

  .table-responsive table {
    min-width: 100%;
  }
  
 
}
/*secondCardT2 */



.card-img-zoom {
  width: 200px;
  height: 200px; 

  background-size: cover;
  background-position: center; 
}

/*zoomcard*/
.left-align-button {
  text-align: left;
  width: auto;
  padding: 10px; 
}


/* Par exemple, pour les écrans de 1030px à 630px */
@media screen and (max-width: 1100px) and (min-width: 100px) {
  .hidden.md\:flex {
    display: flex !important;
  }
  .sm:hidden {
    display: none !important;
  }
}
/* Pour les écrans de 1100px et plus */
@media screen and (min-width: 1030px) {
  .hidden.md\:flex {
    display: none !important;
  }
  .sm:hidden {
    display: none !important;
  }
}




/*css vertical slider */
/* 
.link-style {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1; 
}

.hover-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2d9dd7 ;
  border-radius: 70px 0  0 70px;
  padding: 10px;
  cursor: pointer;
  transition: opacity 0.3s;
  color: #fff; 
  font-size: 66px; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px; 
  height: 145px; 
  opacity: 0; 
}

.card:hover .hover-arrow {
  opacity: 0.6; 
}
*/
/*vertical slider*/ 

.Achievements {
  height: 60%;

}
/*company*/
.mb-4 {
  margin-bottom: 100px; 
}
.image-margin {
  margin-bottom: 10px; 
}

.paragraph-margin {
  margin-bottom: 40px; 
}

/*contact responsive*/
/* Define responsive classes for smaller screens */
@media (max-width: 640px) {
  .table-responsive {
    width: 100%;
  
  }

}


@media (max-width: 1030px) {
  .image-container {
    margin-top: -100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
  }
  .art2{
    margin-left: -300px;
  }
  .wi2{
    margin-left: -200px;
  }
  .text2 {
    margin-left: -150px;
    margin-top: 50px;
    width: 350px;
  }
  .pic {
margin-left: -300px;
width:360px
  }
  .pic2{
    width:340px
  }
  .half {
    height: 1200px;
    margin-left: -110px;
  }
  .text {
    margin-top: 2rem;
    margin-left: -110px;
  }
}
/* carrousel 2*/
.image_wrapper1 {
  margin: 0px 30px;
}

.image_wrapper1 img {
  width: 20%;
}
/* carrousel 2*/
.image_wrapper2 {
  margin: 0px 30px;
}

.image_wrapper2 img {
  width: 20%;
}



/* footer overlay */
.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}
  /*acheivements */
  /* Styles pour les flèches de navigation */
.slick-prev,
.slick-next {
  font-size: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

/* Styles pour les dots (indicateurs de pagination) */
.slick-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
}

.slick-dots li {
  margin: 0 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ccc;
  border: none;
  cursor: pointer;
}

.slick-dots li.slick-active button {
  background: #333;
}
